import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import { api } from "../api";

import { useNavigate } from "react-router-dom";
const AddProd = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const initialValues = {
    image: "",
    color: "",
    description: "",
    category: "",
    size: "",
    price: "",
  };

  const onSubmit = (data) => {
    axios.post(`${api.ENDPOINT}/product`, data).then((response) => {
      navigate(0);
    });
  };
  return (
    <>
      <Button variant="btn btn-success" onClick={handleShow}>
        Add New <span className="fa-solid fa-plus"></span>
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>ADD PRODUCT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="createProductPage">
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              <Form>
                <label for="avatar">Choose a picture:</label>

                <input
                  className="form-control"
                  type="file"
                  id="inputCreateProduct"
                  name="image"
                  accept="image/png, image/jpeg"
                ></input>
                <label>Color</label>
                <Field
                  as="select"
                  className="form-control"
                  id="inputCreateProduct"
                  name="color"
                  required
                >
                  <option>---</option>
                  <option value="WHITE">WHITE</option>
                  <option value="GREY">GREY</option>
                  <option value="BLACK">BLACK</option>
                  <option value="VIOLET">VIOLET</option>
                  <option value="PINK">PINK</option>
                  <option value="BLUE">BLUE</option>
                  <option value="GREEN">GREEN</option>
                  <option value="YELLOW">YELLOW</option>
                  <option value="ORANGE">ORANGE</option>
                  <option value="RED">RED</option>
                  <option value="BROWN">BROWN</option>
                </Field>
                <label>Description</label>
                <Field
                  className="form-control"
                  id="inputCreateProduct"
                  name="description"
                  required
                />
                <label>Category</label>
                <Field
                  as="select"
                  className="form-control"
                  id="inputCreateProduct"
                  name="category"
                  required
                >
                  <option>---</option>
                  <option value="Stripes">Stripes</option>
                  <option value="Abstract">Abstract</option>
                  <option value="Solid Color">Solid Color</option>
                </Field>
                <label>Size</label>
                <Field
                  as="select"
                  className="form-control"
                  id="inputCreateProduct"
                  name="size"
                  required
                >
                  <option>---</option>
                  <option value="10x20">10x20</option>
                </Field>
                <label>Price</label>
                <Field
                  className="form-control"
                  id="inputCreateProduct"
                  name="price"
                  required
                />
                <hr />
                <button
                  type="submit"
                  className="btn btn-success"
                  data-dismiss="modal"
                >
                  Create Product
                </button>
              </Form>
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddProd;
