import React from "react";
import { Link } from "react-router-dom";

const AdminHeader = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-dark fixed-top" id="mainNav">
        <div className="container">
          <Link to="/" className="navbar-brand">
            MangWrapPH
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            Menu
            <i className="fas fa-bars ms-1"></i>
          </button>
          <div
            className="collapse bg-dark navbar-collapse"
            id="navbarResponsive"
          >
            <ul className="navbar-nav text-uppercase ms-auto mx-2 py-4 py-lg-0">
              <li className="nav-item">
                <Link to="/product" className="nav-link">
                  Product List
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/Customer" className="nav-link">
                  Customer List
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/" className="nav-link">
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default AdminHeader;
