import { Image } from "react-bootstrap";
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";

import staticImage from "../assets/product.shop/design5.jpg";

import { api } from "./api";

const ProductCards = () => {
  const [listOfProducts, setListofProducts] = useState([]);

  useEffect(() => {
    axios.get(`${api.ENDPOINT}/product`).then((response) => {
      setListofProducts(response.data);
    });
  }, []);

  return (
    <>
      {listOfProducts.map((value, key) => {
        return (
          <div className="card col-sm-6 col-md-4 col-lg-3 mx-3 mt-3 ">
            <Image
              src={staticImage}
              className="card-img-top"
              alt="WrapTemp1"
              fluid
            />

            <div className="card-body">
              <h6 className="card-subtitle mb-2 text-muted">
                Color: {value.color}
              </h6>
              <p className="card-text">{value.decription}</p>
              <p className="card-subtitle text-muted">Size(ft): {value.size}</p>
              <div className="buy d-flex justify-content-between align-items-center">
                <div className="qty text-info">
                  <h5 className="mt-4">Qty:</h5>
                </div>
                <input
                  type="number"
                  id="quantity"
                  name="quantity"
                  min="1"
                  max="30"
                  placeholder="1"
                />
              </div>
              <div className="buy d-flex justify-content-between align-items-center">
                <div className="price text-success">
                  <h5 className="mt-4">P {value.price}</h5>
                </div>
                <button className="btn btn-warning mt-3">
                  <i className="fas fa-shopping-cart"></i> Buy Now!
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ProductCards;
