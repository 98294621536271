import React from "react";
import Videobg from "../assets/Videobg.mp4";
import time from "../assets/time.png";
import stock from "../assets/stock.png";
import color from "../assets/color.png";
import cars from "../assets/cars.png";
import view from "../assets/view.png";
import Header from "../components/Header";
import Footer from "../components/Footer";
const Home = () => {
  return (
    <>
      <Header />
      <div className="main">
        <video src={Videobg} autoPlay muted loop></video>
        <div className="home-content">
          <div className="masthead-subheading">CHANGE YOUR LIFE</div>
          <div className="masthead-heading text-uppercase">
            CHANGE YOUR STYLE
          </div>
        </div>
      </div>
      <div className="container-fluid virtual">
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="section-about col-6 col-sm-4 col-md-4 col-lg-2 text-center d-flex flex-column justify-content-between my-3">
              <h3 className="text-primary flex-grow-1">
                <span>
                  AVAILABLE
                  <br />
                  24/7
                </span>
              </h3>
              <div className="mt-2">
                <img className="img-fluid" src={time} alt="" />
              </div>
            </div>
            <div className="section-about col-6 col-sm-4 col-md-4 col-lg-2 text-center d-flex flex-column justify-content-between my-3">
              <h3 className="text-primary flex-grow-1">
                <span>
                  350+ READY
                  <br />
                  DESIGNS
                </span>
              </h3>
              <div className="mt-2">
                <img className="img-fluid" src={stock} alt="" />
              </div>
            </div>
            <div className="section-about col-6 col-sm-4 col-md-4 col-lg-2 text-center d-flex flex-column justify-content-between my-3">
              <h3 className="text-primary flex-grow-1">
                <span>
                  COLOR
                  <br />
                  CHANGE
                </span>
              </h3>
              <div className="mt-2">
                <img className="img-fluid" src={color} alt="" />
              </div>
            </div>
            <div className="section-about col-6 col-sm-4 col-md-4 col-lg-2 text-center d-flex flex-column justify-content-between my-3">
              <h3 className="text-primary flex-grow-1">
                <span>
                  FIT TO
                  <br />
                  ANY CAR
                </span>
              </h3>
              <div className="mt-2">
                <img className="img-fluid" src={cars} alt="" />
              </div>
            </div>
            <div className="section-about col-6 col-sm-4 col-md-4 col-lg-2 text-center d-flex flex-column justify-content-between my-3">
              <h3 className="text-primary flex-grow-1">
                <span>
                  3D
                  <br />
                  PREVIEW
                </span>
              </h3>
              <div className="mt-2">
                <img className="img-fluid" src={view} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
