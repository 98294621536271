// import React, { useState, useEffect } from "react";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import { useNavigate } from "react-router-dom";

function RegisterUser() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const initialValues = {
    name: "",
    email: "",
    password: "",
    address: "",
    contact: "",
  };
  const onSubmit = (data) => {
    axios.post("http://localhost:3001/auth", data).then((response) => {
      navigate(0);
    });
  };
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Registration
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold ms-auto">
            User Registration
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="createUserPage">
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              <Form>
                <label>Full Name:</label>
                <Field
                  className="form-control"
                  id="inputCreateUser"
                  name="name"
                  required
                />
                <label>Email:</label>
                <Field
                  type="email"
                  className="form-control"
                  id="inputCreateUser"
                  name="email"
                  required
                />
                <label>Password:</label>
                <Field
                  type="password"
                  className="form-control"
                  id="inputCreateUser"
                  name="password"
                  required
                />
                <label>Address:</label>
                <Field
                  className="form-control"
                  id="inputCreateUser"
                  name="address"
                  required
                />
                <label>Contact</label>
                <Field
                  className="form-control"
                  id="inputCreateUser"
                  name="contact"
                  required
                />
                <hr />
                <button
                  type="submit"
                  className="btn btn-info"
                  data-dismiss="modal"
                >
                  Register
                </button>
              </Form>
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default RegisterUser;
