import React from "react";
import ProductCards from "../components/productcards";
import { Container, Row } from "react-bootstrap";
import Header from "../components/Header";

function Store() {
  return (
    <>
      <Header />
      <div className="main">
        <div className="store-content">
          <div className="filter col-4 col-lg-2 col-sm-3 col-md-3 ">
            <div className="filter">
              <div className="filter-title mt-4 mb-4">
                <h6 className="font-s-m">Colors</h6>
              </div>
              <div className="filter-reset">All colors</div>
              <div className="color-container">
                <label
                  className="checkbox has-tooltip"
                  style={{
                    backgroundColor: "rgb(255, 255, 255)",
                    border: "1px solid rgb(207, 207, 207)",
                  }}
                >
                  <input type="checkbox" value="82" />
                </label>
                <label
                  className="checkbox has-tooltip"
                  style={{ backgroundColor: "rgb(157, 156, 156)" }}
                >
                  <input type="checkbox" value="83" />
                </label>
                <label
                  className="checkbox has-tooltip"
                  style={{ backgroundColor: "rgb(18, 14, 22)" }}
                >
                  <input type="checkbox" value="84" />
                </label>
                <label
                  className="checkbox has-tooltip"
                  style={{ backgroundColor: "rgb(92, 36, 130)" }}
                >
                  <input type="checkbox" value="85" />
                </label>
                <label
                  className="checkbox has-tooltip"
                  style={{ backgroundColor: "rgb(229, 0, 125)" }}
                >
                  <input type="checkbox" value="86" />
                </label>
                <label
                  className="checkbox has-tooltip"
                  style={{ backgroundColor: "rgb(21, 65, 148)" }}
                >
                  <input type="checkbox" value="87" />
                </label>
                <label
                  className="checkbox has-tooltip"
                  style={{ backgroundColor: "rgb(0, 157, 60)" }}
                >
                  <input type="checkbox" value="88" />
                </label>
                <label
                  className="checkbox has-tooltip"
                  style={{ backgroundColor: "rgb(255, 237, 0)" }}
                >
                  <input type="checkbox" value="89" />
                </label>
                <label
                  className="checkbox has-tooltip"
                  style={{ backgroundColor: "rgb(236, 102, 7)" }}
                >
                  <input type="checkbox" value="90" />
                </label>
                <label
                  className="checkbox has-tooltip"
                  style={{ backgroundColor: "rgb(209, 10, 16)" }}
                >
                  <input type="checkbox" value="92" />
                </label>
                <label
                  className="checkbox has-tooltip"
                  style={{ backgroundColor: "rgb(117, 84, 11)" }}
                >
                  <input type="checkbox" value="93" />
                </label>
              </div>
            </div>
            <div className="filter">
              <div className="filter-title mt-4 mb-4">
                <h6 className="font-s-m">Type of wrap</h6>
              </div>
              <div className="category-container">
                <button
                  type="button"
                  className="category btn btn-outline-info text-dark btn-sm"
                >
                  Stripes
                </button>
                <button
                  type="button"
                  className="category btn btn-outline-info text-dark btn-sm"
                >
                  Abstract
                </button>
                <button
                  type="button"
                  className="category btn btn-outline-info text-dark btn-sm"
                >
                  Solid Color
                </button>
              </div>
            </div>
          </div>
          <div className="thumbnails col-8 col-lg-10 col-sm-9 col-md-9">
            <Container className="card_container">
              <Row>
                <ProductCards />
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}

export default Store;
