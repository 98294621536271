import React from "react";
import AdminHeader from "../../components/admin-side/AdminHeader";
import ProdList from "../../components/admin-side/prodlist";
import AddProd from "../../components/admin-side/AddProd";
const Product = () => {
  return (
    <>
      <AdminHeader />
      <div className="container prodlist">
        <h2>List of Products</h2>
        <AddProd />
        <div className="tabble table-responsive table-hover my-2">
          <table className="table  prod-table table-bordered">
            <thead className="">
              <tr>
                <th>Product ID</th>
                <th>Product Image</th>
                <th>Color Category</th>
                <th>Description</th>
                <th>Style Category</th>
                <th>Size (feet)</th>
                <th>Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <ProdList />
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Product;
