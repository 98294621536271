import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Store from "./pages/Store";
import Customer from "./pages/admin-side/Customer";
import Product from "./pages/admin-side/Product";
// import Prod from "./pages/admin-side/Prod";
import Edit from "./pages/admin-side/Edit";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route index element={<Home />} />
          <Route path="/store" element={<Store />} />
          <Route path="/product" element={<Product />} />
          <Route path="/Customer" element={<Customer />} />
          {/*<Route path="/prod/:id" element={<Prod />} /> */}
          <Route path="/edit/:id" element={<Edit />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
