import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import RegisterUser from "../components/RegisterUser";
import axios from "axios";

import { api } from "../components/api";

function Loginuser() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const login = () => {
    console.log(api.ENDPOINT);
    const data = { email: email, password: password };
    axios.post(`${api.ENDPOINT}/auth/login`, data).then((response) => {
      if (response.data.error) {
        alert(response.data.error);
      } else {
        sessionStorage.setItem("accessToken", response.data);
      }
    });
  };
  return (
    <>
      <Button variant="btn btn-warning" onClick={handleShow}>
        UserLogin/Registration
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>User Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Email</label>
          <input
            className="form-control"
            type="email"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <label>Password</label>
          <input
            className="form-control"
            type="password"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
          <button className="btn  btn-info mt-2" onClick={login}>
            Login
          </button>
        </Modal.Body>
        <Modal.Footer>
          <RegisterUser />
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Loginuser;
