import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../api";
import staticImage from "../../assets/product.shop/design5.jpg";

// import design9 from "../../assets/product.shop/design9.jpg";

const ProdList = () => {
  const [listOfProducts, setListofProducts] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    axios.get(`${api.ENDPOINT}/product`).then((response) => {
      setListofProducts(response.data);
    });
  }, []);

  const deleteProduct = (id) => {
    axios.delete(`${api.ENDPOINT}/product/${id}`).then((response) => {
      alert("delete Success");
      navigate(0);
    });
  };

  return (
    <>
      {listOfProducts.map((value, key) => {
        return (
          <tr>
            <td>{value.id}</td>
            <td className="d-flex justify-content-center">
              <img className="table-image" src={staticImage} alt="Design" />
            </td>
            <td>{value.color}</td>
            <td>{value.description}</td>
            <td>{value.category}</td>
            <td>
              {value.size} <td>ft</td>
            </td>
            <td>P {value.price} </td>
            <td>
              <button
                onClick={() => {
                  deleteProduct(value.id);
                }}
                className="btn"
              >
                <span className="fa-solid fa-trash red-color"></span>
              </button>
              <button
                onClick={() => {
                  navigate(`/edit/${value.id}`);
                }}
                className="btn"
              >
                <span className="fa-solid fa-edit orange-color"></span>
              </button>
            </td>
          </tr>
        );
      })}
    </>
  );
};

export default ProdList;
