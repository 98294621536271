import React from "react";
import AdminHeader from "../../components/admin-side/AdminHeader";
import customerList from "../../components/admin-side/userIist";

const Customer = () => {
  return (
    <>
      <AdminHeader />
      <div className="container prodlist">
        <h2>List of Customer</h2>
        <div className="tabble table-responsive table-hover my-2">
          <table className="table  prod-table table-bordered">
            <thead className="">
              <tr>
                <th>Customer ID</th>
                <th>FullName</th>
                <th>Email</th>
                <th>Password</th>
                <th>Complete Address</th>
                <th>Contact No</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <customerList />
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Customer;
