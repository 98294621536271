import React from "react";
import AdminHeader from "../../components/admin-side/AdminHeader";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { api } from "../../components/api";

function Edit() {
  let { id } = useParams();
  const navigate = useNavigate();
  const initialValues = {
    image: "",
    color: "",
    description: "",
    category: "",
    size: "",
    price: "",
  };
  const updateProduct = (data) => {
    axios.put(`${api.ENDPOINT}/product/${id}`, data).then((response) => {
      alert("update Success");
      navigate(`/product`);
    });
  };
  return (
    <>
      <AdminHeader />
      <div className="container prodlist">
        <div className="createProductPage">
          <Formik initialValues={initialValues} onSubmit={updateProduct}>
            <Form>
              {id}
              <label for="avatar">Choose a picture:</label>

              <input
                className="form-control"
                type="file"
                id="inputCreateProduct"
                name="image"
                accept="image/png, image/jpeg"
              ></input>
              <label>Color</label>
              <Field
                as="select"
                className="form-control"
                id="inputCreateProduct"
                name="color"
                required
              >
                <option>---</option>
                <option value="WHITE">WHITE</option>
                <option value="GREY">GREY</option>
                <option value="BLACK">BLACK</option>
                <option value="VIOLET">VIOLET</option>
                <option value="PINK">PINK</option>
                <option value="BLUE">BLUE</option>
                <option value="GREEN">GREEN</option>
                <option value="YELLOW">YELLOW</option>
                <option value="ORANGE">ORANGE</option>
                <option value="RED">RED</option>
                <option value="BROWN">BROWN</option>
              </Field>
              <label>Description</label>
              <Field
                className="form-control"
                id="inputCreateProduct"
                name="description"
                required
              />
              <label>Category</label>
              <Field
                as="select"
                className="form-control"
                id="inputCreateProduct"
                name="category"
                required
              >
                <option>---</option>
                <option value="Stripes">Stripes</option>
                <option value="Abstract">Abstract</option>
                <option value="Solid Color">Solid Color</option>
              </Field>
              <label>Size</label>
              <Field
                as="select"
                className="form-control"
                id="inputCreateProduct"
                name="size"
                required
              >
                <option>---</option>
                <option value="10x20">10x20</option>
              </Field>
              <label>Price</label>
              <Field
                className="form-control"
                id="inputCreateProduct"
                name="price"
                required
              />
              <hr />
              <button type="submit" className="btn">
                <span className="fa-solid fa-edit orange-color"></span> UPDATE
                PRODUCT
              </button>
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
}

export default Edit;
